import React from 'react'
import Navbar2 from '../Navbar/navbar2';
import Footer2 from '../Footer/Footer2';
export default function FAQ() {
    return (
        <>
            <Navbar2 />
            <div class="accordion-1 circle-shape-faq">
                <div className="beforeimgfirst  circle-shape-faq2">
                    <div class="container">
                        <div class="row my-2">
                            <div class="col">
                                <div className="faqheaidng">
                                    <h1>FAQs</h1>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-5">
                            <div class="col-xs-12 mt-4">
                                <div class="accordion" id="accordionRental">
                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading1">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                                                1.&nbsp; What is CredFin?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="heading1" data-bs-parent="#accordionRental" >
                                            <div class="accordion-body text-sm opacity-8">
                                                CredFin is an instant lending platform that allows you to apply for loans quickly and easily online.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading2">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                                2.&nbsp; How do I apply for a loan?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#accordionRental">
                                            <div class="accordion-body text-sm opacity-8">
                                                To apply for a loan, download our app from the App Store/Google Play, create an account, and complete the application form. Our automated system will process your request.<a href="https://play.google.com/store/apps/details?id=com.credfin.money" target="_blank">Apply Now Link</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading3">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                                3.&nbsp; What types of loans do you offer?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionRental">
                                            <div class="accordion-body text-sm opacity-8">
                                                We offer various types of loans, including personal loans, payday loans, and business loans. You can check our loan products section for more details.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading4">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                                4.&nbsp;  What are the eligibility criteria for a loan?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#accordionRental">
                                            <div class="accordion-body text-sm opacity-8">
                                                Eligibility criteria vary based on the type of loan you are applying for. Common requirements include age, income, and credit score. Specific eligibility details can be found in the loan product descriptions.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading5">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                                5.&nbsp;  How long does it take to get approved for a loan?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#accordionRental">
                                            <div class="accordion-body text-sm opacity-8">
                                                Approval times vary, but many applicants receive loan approval within a fraction of a minute. For more precise information, please check the loan application process section.
                                            </div>
                                        </div>
                                    </div>


                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading6">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                                6.&nbsp;   What interest rates do you offer?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#accordionRental" >
                                            <div class="accordion-body text-sm opacity-8">
                                                Interest rates depend on several factors, including the type of loan and your creditworthiness. You can find specific interest rate information in the loan product details.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading7">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                                7.&nbsp;   How can I repay my loan?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse7" class="accordion-collapse collapse" aria-labelledby="heading7" data-bs-parent="#accordionRental">
                                            <div class="accordion-body text-sm opacity-8">
                                                We offer flexible repayment options, including automatic deductions from your bank account & manual repayments through the app
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading8">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                                8.&nbsp;   Is my personal information safe with CredFin?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse8" class="accordion-collapse collapse" aria-labelledby="heading8" data-bs-parent="#accordionRental">
                                            <div class="accordion-body text-sm opacity-8">
                                                Yes, we take data security seriously. We use advanced encryption and security measures to protect your personal information.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading9">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                                9.&nbsp;    Can I get a loan with bad credit?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse9" class="accordion-collapse collapse" aria-labelledby="heading9" data-bs-parent="#accordionRental">
                                            <div class="accordion-body text-sm opacity-8">
                                                We consider applicants with various credit profiles. While a good credit score may improve your approval chances, we provide options for individuals with less-than-perfect credit.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading10">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                                                10.&nbsp;   What happens if I miss a loan repayment?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse10" class="accordion-collapse collapse" aria-labelledby="heading10" data-bs-parent="#accordionRental">
                                            <div class="accordion-body text-sm opacity-8">
                                                Missing loan repayments may result in late fees or penalties. It's important to communicate with us if you anticipate difficulties in making payments. We can provide solutions to help you manage your loan.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading11">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                                                11.&nbsp;     How can I contact customer support?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse11" class="accordion-collapse collapse" aria-labelledby="heading11" data-bs-parent="#accordionRental" >
                                            <div class="accordion-body text-sm opacity-8">
                                                You can contact our customer support team through support@credfin.money.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading12">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                                                12.&nbsp;   What are the loan application fees?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse12" class="accordion-collapse collapse" aria-labelledby="heading12" data-bs-parent="#accordionRental">
                                            <div class="accordion-body text-sm opacity-8">
                                                Application fees, if applicable, are outlined in the loan terms and conditions. Some loans may have processing fees or origination fees, so it's important to review the terms before applying.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading13">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                                                13.&nbsp;    How can I check the status of my loan application?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse13" class="accordion-collapse collapse" aria-labelledby="heading13" data-bs-parent="#accordionRental">
                                            <div class="accordion-body text-sm opacity-8">
                                                You can check the status of your loan application by logging into your CredFin account and navigating to the "Loan Report" section.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading14">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                                                14.&nbsp;  Can I pay off my loan early without penalties?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse14" class="accordion-collapse collapse" aria-labelledby="heading14" data-bs-parent="#accordionRental">
                                            <div class="accordion-body text-sm opacity-8">
                                                Yes, we typically allow borrowers to pay off their loans early without penalties. Please check your loan agreement for specific details.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item mb-3">
                                        <h5 class="accordion-header" id="heading15">
                                            <button class="accordion-button border-bottom font-weight-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse15" aria-expanded="false" aria-controls="collapse15">
                                                15.&nbsp;  What happens if my loan application is Rejected?
                                                <i class="collapse-close fa fa-plus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                                <i class="collapse-open fa fa-minus text-xs pt-1 position-absolute end-0 me-3" aria-hidden="true"></i>
                                            </button>
                                        </h5>
                                        <div id="collapse15" class="accordion-collapse collapse" aria-labelledby="heading15" data-bs-parent="#accordionRental">
                                            <div class="accordion-body text-sm opacity-8">
                                                If your loan application is Rejected, we will provide you with a reason for the decision. You may have the option to reapply or explore other loan products that better suit your circumstances.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <Footer2 />
        </>
    )
}
