import React from 'react'
import '../../css/Footer.scss'
import appstoreicon from '../../assets/appstore.png';
import { Link, NavLink, useNavigate } from 'react-router-dom'
import playstoreicon from '../../assets/playstore.png';
import { BiPhoneCall } from 'react-icons/bi'
import logoWhite from '../../assets/credFin-logo-white.png'
import { AiOutlineMail } from 'react-icons/ai'
import XMLFILE from '../../Utils/sitemap.xml'

export default function Footer2() {

    let navigate = useNavigate();

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleLinkClick1 = (sectionId) => {

        navigate('/');


        setTimeout(() => {

            window.scrollToSection(sectionId);
        }, 200);
    };
    return (
        <>
            <footer className=" text-light" style={{ backgroundColor: '#006C67' }}>
                <div className="circle-shape2" onClick={scrollToTop}></div>
                <div className="svg-shape" ></div>
                <div className="container pt-3">
                    <div className="f-items default-padding pt-5">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 item">
                                <div className="f-item about">
                                    {/* <img src={logoWhite} alt="logo" style={{ maxWidth: "200px" }} /> */}
                                    <Link className="nav-link ps-0" to={{
                                        pathname: "/",
                                    }} onClick={scrollToTop}><img src={logoWhite} alt="logo" style={{ maxWidth: "200px" }} /></Link>
                                    <div className="address">
                                        <ul>

                                            <li style={{ marginTop: '10px' }}>
                                                <div className="icon"><AiOutlineMail className="iconinside" /></div>
                                                <div className="info">
                                                    {/* <h5>Email: <span>support@credfin.money</span></h5> */}
                                                    <h5>Email: <a href="mailto:support@credfin.money" style={{ cursor: 'pointer', fontSize: '17px', fontWeight: '500', color: 'white' }}>support@credfin.money</a></h5>
                                                </div>
                                            </li>
                                            {/* <li style={{ marginTop: '25px' }}>
                                                <div className="icon"><BiPhoneCall className="iconinside" /></div>
                                                <div className="info">
                                                    <h5>Phone: <span>+918010700600</span></h5>

                                                </div>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 item">
                                <div className="f-item link">
                                    <h4 className="widget-title">Company</h4>
                                    <ul>
                                        {/* <li>
                                            <Link className="nav-link ps-0" to={{
                                                pathname: "/",
                                            }}>Home</Link>
                                        </li> */}
                                        {/* <li>
                                            <Link className="nav-link ps-0" to="/" onClick={() => handleLinkClick1('AU')}>About us</Link>
                                            
                                        </li> */}
                                        <li>
                                            <Link className="nav-link ps-0" to={{
                                                pathname: "/home/Aboutus",
                                            }} onClick={scrollToTop}>About us</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link ps-0" to="/" onClick={() => handleLinkClick1('WWO')}>What We Offer</Link>
                                            {/* <a href="#WWO">What we offer</a> */}
                                        </li>

                                        {/* <li>
                                            <a href="#FAQ">FAQs</a>
                                        </li> */}

                                        <li>
                                            <Link className="nav-link ps-0" to="/" onClick={() => handleLinkClick1('LS')}>Loan Steps</Link>
                                            {/* <a href="#LS">Loan Steps</a> */}
                                        </li>
                                        <li>
                                            <Link className="nav-link ps-0" to="/" onClick={() => handleLinkClick1('EC')}>EMI Calculator</Link>
                                            {/* <a href="#EC">EMI Calculator</a> */}
                                        </li>
                                        <li>
                                            <Link className="nav-link ps-0" to="/" onClick={() => handleLinkClick1('WA')}>Why Apply</Link>
                                            {/* <a href="#WA">Why apply</a> */}
                                        </li>
                                        <li>
                                            <Link className="nav-link ps-0" to={{
                                                pathname: "/home/ContactUs",
                                            }} onClick={scrollToTop}>Contact Us</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 item">
                                <div className="f-item link">
                                    <h4 className="widget-title">Useful Link</h4>
                                    <ul>
                                        <li>
                                            <Link className="nav-link ps-0" to={{
                                                pathname: "/home/Support",
                                            }} onClick={scrollToTop}>Support</Link>
                                        </li>
                                        <li>
                                            <Link className="nav-link ps-0" to={{
                                                pathname: "/home/GreivenceRedressal",
                                            }} onClick={scrollToTop}>Grievance Redressal</Link>
                                            {/* <a href="/GreivenceRedressal">Grievance Redressal</a> */}
                                        </li>
                                        <li>
                                            <Link className="nav-link ps-0" to={{
                                                pathname: "/home/PrivacyPolicy",
                                            }} onClick={scrollToTop}>Privacy Policy</Link>
                                            {/* <a href="/PrivacyPolicy">Privacy Policy</a> */}
                                        </li>
                                        <li>
                                            <Link className="nav-link ps-0" to={{
                                                pathname: "/home/TermsCondition",
                                            }} onClick={scrollToTop}>Terms & Conditions</Link>
                                            {/* <a href="/Terms&Condition">Terms & Conditions</a> */}
                                        </li>
                                        <li>
                                            <Link className="nav-link ps-0" to={{
                                                pathname: "/home/CancellationPolicy",
                                            }} onClick={scrollToTop}>Refund & Cancellation Policy</Link>
                                            {/* <a href="/FAQ">FAQs</a> */}
                                        </li>
                                        <li>
                                            <Link className="nav-link ps-0" to={{
                                                pathname: "/home/FAQ",
                                            }} onClick={scrollToTop}>FAQs</Link>
                                            {/* <a href="/FAQ">FAQs</a> */}
                                        </li>
                                        <li>
                                            <a className="nav-link ps-0" href={XMLFILE} target="_blank">Site Map</a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 item">
                                <div className="row">
                                    <div className="btns1">
                                        <div className="button">
                                            <a href="https://play.google.com/store/apps/details?id=com.credfin.money" target="_blank" className="buttonapplynow">
                                                Apply Now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4">
                                    <h4 className='get_app' >Get App Now</h4>
                                    <div className="btns1 mt-2">
                                        <div className="button">
                                        <a href="https://play.google.com/store/apps/details?id=com.credfin.money" target="_blank" className="buttonapply2" style={{maxWidth:'205px'}}>
                                                <img src={playstoreicon} width={'25%'} alt="playstore_icon" />
                                                    <h6 className='footerbuttontext'>Available on <br></br>Playstore</h6>
                                                {/* <div>
                                                    <h6 className='footerbuttontext'>Playstore</h6>

                                                </div> */}
                                            </a>
                                            {/* <button className="buttonapply2" >
                                                <img src={playstoreicon} width={'25%'} alt="" />
                                                <div>
                                                    <h6 className='footerbuttontext'>Available on</h6>
                                                    <h6 className='footerbuttontext'>Playstore</h6>

                                                </div>
                                            </button> */}
                                        </div>
                                    </div>
                                    {/* <div className="btns1 mt-2">
                                        <div className="button">
                                            <button className="buttonapply2">
                                                <img src={appstoreicon} width={'25%'} alt="" />
                                                <div>
                                                    <h6 className='footerbuttontext'>Available on</h6>
                                                    <h6 className='footerbuttontext'>Appstore</h6>
                                                </div>
                                            </button>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom mt-3">
                    <div className="container">
                        <div className="row">

                            <div className="col text-center">
                                <h5 className='f-b' >copyright © Credfin Services Private Limited | All Rights Reserved</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
