import { Link, NavLink, useNavigate } from 'react-router-dom'
import darkLogo from '../../assets/credFin-logo.png'
import { useEffect, useRef, useState } from 'react';
import '../../css/Navbar.scss';
function Navbar2() {

    let navRef = useRef(null);
    let navigate = useNavigate();
    const [screenSize, setScreenSize] = useState('small');
    useEffect(() => {
        let width = window.screen.width;
        if (width <= 991) {
            setScreenSize('small');
        } else {
            setScreenSize('large');
        }
    }, [])

    function navClickHandler() {
        if (screenSize === 'small') {
            if (navRef) {
                navRef.current.classList.toggle("opened")
            }
        }
    }

    const handleLinkClick = (sectionId) => {

        navigate('/');


        setTimeout(() => {

            window.scrollToSection(sectionId);
        }, 200);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    return (
        <nav ref={navRef} className="navbar navbar-light navbar-expand-lg fixed-top">
            <div className="container">
                <Link className="navbar-brand" to={{ pathname: "/" }} onClick={scrollToTop}>
                    <img src={darkLogo} alt="Logo" width="120" height="auto" />
                </Link>
                <button onClick={navClickHandler} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav m-auto mb-2 mb-lg-0" >
                        {/* <li className="nav-item">
                        <NavLink className='nav-link'  aria-current="page">Home</NavLink>
                    </li> */}
                        <li className="nav-item">
                            <Link className="nav-link" to="/" onClick={() => handleLinkClick('WWO')}>What We Offer</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/" onClick={() => handleLinkClick('LS')}>Loan Steps</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/" onClick={() => handleLinkClick('WA')}>Why Apply</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/" onClick={() => handleLinkClick('EC')}>EMI Calculator</Link>
                        </li>
                        <li className="nav-item">
                            {/* <Link className="nav-link" to="/" onClick={() => handleLinkClick('FAQ')}>FAQ</Link> */}
                            <Link className="nav-link ps-0" to={{
                                pathname: "/home/FAQ",
                            }} onClick={scrollToTop}>FAQs</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link ps-0" to={{
                                pathname: "/home/ourPartner",
                            }} onClick={scrollToTop}>Our Partner</Link>
                        </li>
                    </ul>
                    {/* <button className='btn bg-light pt-2 pb-2'>Apply Now</button> */}
                    <a href="https://play.google.com/store/apps/details?id=com.credfin.money" target="_blank" className="btn bg-light pt-2 pb-2">
                        Apply Now
                    </a>
                </div>
            </div>
        </nav>
    )
}

export default Navbar2