import React, { useEffect, useState } from 'react'
import Slider from '@mui/material/Slider';

export default function EmiCalculator() {
    const [value, setValue] = React.useState(10000);
    const [value2, setValue2] = React.useState(12);
    const [value3, setValue3] = React.useState(3);

    let widths = window.innerWidth;

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleInputChange = (event) => {
        setValue(event.target.value === '' ? 0 : Number(event.target.value));
    };
    const handleSliderChange2 = (event, newValue) => {
        setValue2(newValue);
    };

    const handleInputChange2 = (event) => {
        setValue2(event.target.value === '' ? 0 : Number(event.target.value));
    };
    const handleSliderChange3 = (event, newValue) => {
        setValue3(newValue);
    };

    const handleInputChange3 = (event) => {
        setValue3(event.target.value === '' ? 0 : Number(event.target.value));
    };
    let totalInterest = (value * (1 + value2 * (value3 / 12))) / 200;
    let TotalAmount = value + totalInterest;
    let Emi = TotalAmount / value3;
    return (
        <>
            <div className="calculator pt-5 pb-5" id="EC" >
                <div className="container">
                    <div className="row" >
                        <div className="col">
                            <div className="heading">
                                <h3 className='fw-bold'>EMI CALCULATOR</h3>
                            </div>
                        </div>
                    </div>
                    <div className={`row ${(widths >= '1440') ? 'row px-5' : (widths >= '1024') ? 'row px-4' : (widths >= '768') ? 'row px-3' : (widths < '768') ? 'row px-2' : ''}`} id="rowsfirst">
                        <div className="col-lg-7 col-xs-12">
                            <div className="sliders">
                                <div className="LoanAmount pt-5">
                                    <div className="row">
                                        <div className="col">
                                            <h5>Loan Amount</h5>
                                        </div>
                                        <div className="col">

                                            <div class="input-group mb-3 ">
                                                <input type="text" class="form-control" aria-describedby="basic-addon2" value={"₹" + " " + value} onChange={handleInputChange} disabled />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <Slider
                                                value={typeof value === 'number' ? value : 0}
                                                onChange={handleSliderChange}
                                                aria-labelledby="input-slider"
                                                min={10000}
                                                max={500000}
                                                step={10000}
                                                style={{ borderRadius: '12px', height: '10px' }}
                                            />
                                        </div>

                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <h5 className="valuelabels">10K</h5>
                                        </div>
                                        <div className="col">
                                            <h5 className="valuelabels" style={{ float: 'right' }}>5L</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="InterestRate pt-3">
                                    <div className="row">
                                        <div className="col">
                                            <h5>Interest Rate</h5>
                                        </div>
                                        <div className="col">

                                            <div class="input-group mb-3 ">
                                                <input type="text" class="form-control" aria-describedby="basic-addon2" value={value2 + " " + "%"} onChange={handleInputChange2} disabled />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Slider
                                                value={typeof value2 === 'number' ? value2 : 0}
                                                onChange={handleSliderChange2}
                                                aria-labelledby="input-slider"
                                                min={12}
                                                max={30}
                                                step={1}
                                                style={{ borderRadius: '12px', height: '10px' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="valuelabels" >12%</h5>
                                        </div>
                                        <div className="col">
                                            <h5 className="valuelabels" style={{ float: 'right' }}>30%</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="SelectEmiOption pt-3">
                                    <div className="row">
                                        <div className="col">
                                            <h5>Select EMI Options</h5>
                                        </div>
                                        <div className="col">

                                            <div class="input-group mb-3 ">
                                                <input type="text" class="form-control" aria-describedby="basic-addon2" value={value3 + " " + "Months"} onChange={handleInputChange3} disabled />

                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Slider
                                                value={typeof value3 === 'number' ? value3 : 0}
                                                onChange={handleSliderChange3}
                                                aria-labelledby="input-slider"
                                                min={3}
                                                max={36}
                                                step={3}
                                                style={{ borderRadius: '12px', height: '10px' }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="valuelabels">3 Months</h5>
                                        </div>
                                        <div className="col">
                                            <h5 className="valuelabels" style={{ float: 'right' }}>36 Months</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-xs-12 mt-5" >
                            <div className="emi">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="emivalue">
                                            <h5>Your Monthly Installment:</h5>
                                            <h3>₹ {Emi.toFixed(2)}</h3>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="calculations">

                                            <div className="row mt-3">
                                                <div className="col">
                                                    <h5>Total Interest</h5>
                                                </div>
                                                <div className="col">
                                                    <h5 className='values'>{totalInterest}</h5>
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col">
                                                    <h5>Principal Amount</h5>
                                                </div>
                                                <div className="col">
                                                    <h5 className='values'>{value}</h5>
                                                </div>
                                            </div>

                                            <hr />

                                            <div className="row mt-4">
                                                <div className="col">
                                                    <h5 className='h5'>Total Amount</h5>
                                                </div>
                                                <div className="col">
                                                    <h5 className='h5values'>{TotalAmount}</h5>
                                                </div>
                                            </div>

                                            <div className="row mt-4 mb-4 px-1">
                                                {/* <button className="buttonapply">Apply Now</button> */}
                                                <a href="https://play.google.com/store/apps/details?id=com.credfin.money" target="_blank" className="buttonapply">
                                                    Apply Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
