import React from 'react';

export const Homepage = React.lazy(() => import('../FirstPageComponents/Homepage.jsx'));
export const FAQ = React.lazy(() => import('../components/MainPages/FAQ.js'));
export const PrivacyPolicy = React.lazy(() => import('../components/MainPages/PrivacyPolicyPage.js'));
export const TermsCondition = React.lazy(() => import('../components/MainPages/TermsConditionPage.js'));
export const GreivenceRedressal = React.lazy(() => import('../components/MainPages/GreivenceRedressal.js'));
export const Support = React.lazy(() => import('../components/MainPages/Support.js'));
export const CancellationPage = React.lazy(() => import('../components/MainPages/CancellationPolicyPage.js'));

export const Aboutus = React.lazy(() => import('../components/MainPages/AboutUs.js'));
export const Contactus = React.lazy(() => import('../components/MainPages/ContactUs.js'));
export const Message = React.lazy(() => import('../components/MainPages/PopupPage.js'));
export const OurPartner = React.lazy(() => import('../components/MainPages/OurPartners.js'));
