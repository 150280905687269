import React from 'react'
import '../../css/Policy.scss';
import Navbar2 from '../Navbar/navbar2';
import Footer2 from '../Footer/Footer2';
export default function GreivenceRedressal() {
    return (
        <>
            <Navbar2 />
            <div className="GreivenceRedressal mb-5     ">
                <div className="container">
                    <div className="row">
                        {/* <h2 className='text-center'>GRIEVANCE REDRESSAL</h2> */}

                        <h2 style={{paddingLeft:'32px',borderLeft:'6px solid #FF6337'}}>Credfin Services Private Limited</h2>
                        <h4 className='mb-5'>Grievance Redressal Policy</h4>

                        <p>The primary objective of this Grievance Redressal Policy is to establish a transparent and efficient mechanism for addressing and resolving grievances raised by users of our lending platform. We are committed to providing a fair and timely resolution to all grievances to enhance customer satisfaction and trust in our services.</p>

                        <p>
                            This policy covers all types of grievances related to the lending platform, including but not limited to loan application, approval, disbursement, repayment, interest rates, fees, and any other issues faced by users during their interaction with the platform.
                        </p>

                        <h5>Grievance Redressal Officer:</h5>
                        <p>A designated Grievance Redressal Officer (GRO) will be appointed to oversee the grievance redressal process. The GRO will be responsible for ensuring that all grievances are handled promptly and fairly.
                        </p>
                        <h5>Submission of Grievances:</h5>
                        <p>Users can submit their grievances through the following channels:
                        </p>
                        <ol>
                            <li>In-app grievance submission form.
                            </li>
                            <li>Email to the designated grievance redressal email address.
                            </li>
                            <li>Written communication addressed to the Grievance Redressal Officer.</li>
                        </ol>
                        <p>Upon receiving a grievance, an acknowledgment will be sent to the user within 24 hours. The acknowledgment will include a unique reference number for tracking purposes.
                        </p>
                        <p>The Grievance Redressal Officer will conduct a thorough investigation of the grievance and take necessary actions to resolve it. The timeline for resolution will not exceed 15 working days from the date of receipt of the grievance.
                        </p>
                        <p>If the user is dissatisfied with the resolution provided by the Grievance Redressal Officer, they have the option to escalate the matter to the next level of management. Contact details for escalation will be provided in the final resolution communication.
                        </p>
                        <p>A periodic review of grievance redressal activities will be conducted to identify trends, areas for improvement, and opportunities for preventive action. A summary report of grievance redressal activities will be shared with relevant stakeholders.
                        </p>
                        <p>Staff involved in the grievance redressal process will undergo regular training to stay updated on the latest regulations, customer service best practices, and effective communication techniques.

                        </p>
                        <p>This Grievance Redressal Policy will be made available to all users through the lending platform's website, FAQs, and other relevant communication channels.

                        </p>
                        <p>We are committed to continuously improving our grievance redressal process based on feedback, emerging best practices, and changing customer needs.
                        </p>
                        <p>This Grievance Redressal Policy is in compliance with applicable laws and regulations governing the financial services sector.

                        </p>

                        {/* <p>
                            Contact Information:
                            <br />
                            Name:
                            <br />
                            Address:
                            <br />
                            Mobile Number:
                            <br />
                            Email:
                        </p> */}

                        <p>This policy will be reviewed periodically and updated as necessary to ensure its continued effectiveness and compliance with regulatory requirements.
                        </p>
                    </div>
                </div>
            </div>
        <Footer2 />
        </>
    )
}
