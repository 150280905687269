import React, { useState, useEffect } from 'react'
import Navbar2 from '../Navbar/navbar2';
import Footer2 from '../Footer/Footer2';
import '../../css/Policy.scss';
import iicon from '../../assets/iicon.png'
import Erroricon from '../../assets/Erroricon.png'
import Erroricon2 from '../../assets/Erroricon2.png'
import tickicon from '../../assets/tickicon.png'
import tickicon2 from '../../assets/tickicon2.png'
import Checkbox from '@mui/material/Checkbox';

import { dataDeleteRequest, sendotp, verifyotp } from '../../Access/actionCreator';
export default function Support() {

    const [emailerrormessage, setemailerrormessage] = useState(false);
    const [numbererrormessage, setnumbererrormessage] = useState(false);
    const [otpbox, setotpbox] = useState(false);
    const [otpsuccessmessage, setotpsuccessmessage] = useState(false);
    const [sorrypopup, setsorrypopup] = useState(false);
    const [sorrypopup2, setsorrypopup2] = useState(false);
    const [sorrypopup3, setsorrypopup3] = useState(false);
    const [confirmdeletepopup, setconfirmdeletepopup] = useState(false);
    const [deleterequestplacedpopup, setdeleterequestplacedpopup] = useState(false)
    const [removerequestbtn, setremoverequestbtn] = useState(false)
    const [reqid, setreqid] = useState()
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setemail] = useState();
    const [selectedcheckbox, setselectedcheckbox] = useState(false)
    const [deletedropdown, setdeletedropdown] = useState('')
    const [verifybtn, setverifybtn] = useState(false);
    const [enterotp, setenterotp] = useState(false);

    const handlePhoneNumberChange = (e) => {
        let inputNumber = e.target.value;
        let truncatedNumber = inputNumber.slice(0, 10);
        setPhoneNumber(truncatedNumber);
    };

    const handleemailchnage = (e) => {
        var emailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        const inputValue = e.target.value;
        setemail(e.target.value);
        console.log("iput value", inputValue)
        if (emailFormat.test(inputValue)) {
            console.log(inputValue);

            setemail(inputValue);
            setemailerrormessage(false); // Set to false when the email is valid
        } else {
            setemailerrormessage(true);
            setTimeout(() => {
                setemailerrormessage(false);
            }, 3000);
        }
    }


    const changeHandlerdropdown = (e) => {
        setdeletedropdown(e);
    }

    const handleOTP = (e) => {
        const inputValue = e.target.value;

        if (!/^\d?$/.test(inputValue)) {
            // If input is not empty or not a digit, reset the input value
            e.target.value = '';
            return;
        }

        const inputBox = e.target.id.charAt(6) * 1;
        const prev = 'digit-' + (inputBox - 1);
        const next = 'digit-' + (inputBox + 1);

        if (e.key === 'Backspace') {
           
            if (inputValue === '' && inputBox > 1) {
                e.preventDefault(); 
                document.getElementById(prev).focus();
            } else if (inputValue === '' && inputBox === 1) {
                e.preventDefault(); 
            } else if (inputBox > 1) {
                e.target.value = '';
                document.getElementById(prev).focus();
            }
        } else if (/^\d$/.test(inputValue) && inputBox < 5) {

            document.getElementById(next).focus();
        }
    };

    const handlePaste = (e) => {
        const pastedData = e.clipboardData?.getData('text/plain');
        if (!pastedData) {
            
            const pastedDataFallback = e.target.value;
            const digits = pastedDataFallback.match(/\d/g);
            if (digits && digits.length === 4) {
                const inputBoxes = document.querySelectorAll('[id^=digit-]');
                for (let i = 0; i < Math.min(digits.length, inputBoxes.length); i++) {
                    inputBoxes[i].value = digits[i];
                    if (i < inputBoxes.length - 1) {
                        inputBoxes[i].dispatchEvent(new Event('input', { bubbles: true }));
                    }
                }
            }
        } else {
            const digits = pastedData.match(/\d/g);
            if (digits && digits.length === 4) {
                const inputBoxes = document.querySelectorAll('[id^=digit-]');
                for (let i = 0; i < Math.min(digits.length, inputBoxes.length); i++) {
                    inputBoxes[i].value = digits[i];
                    if (i < inputBoxes.length - 1) {
                        inputBoxes[i].dispatchEvent(new Event('input', { bubbles: true }));
                    }
                }
            }
        }
        e.preventDefault();
    };
    useEffect(() => {
        if (otpbox === true) {
            document.getElementById('digit-1').focus()
        }

    }, [])

    const handleSelect = () => {
        setselectedcheckbox(!selectedcheckbox);
    }

    const handleOTPRequest = () => {
        let obj = {
            // "fullName":"gyuh",
            "mobileNo": phoneNumber,
            "email": email,
            "deleteType": deletedropdown,
            "dataList": ["test", "data"]
        }
        console.log("callback", obj);
        dataDeleteRequest(obj, (callback) => {
            console.log("callback", callback);
            if (callback.status === 'failure') {
                setemailerrormessage(true);
                setnumbererrormessage(true);
            }
            else if (callback.status === 'success' && callback.message === 'Cannot Delete, User Have Loan Book with us') {
                setsorrypopup(true);
            }
            else if (callback.status === 'success' && callback.message === 'Delete Request Already Present' && callback.consentVerified === true) {
                setsorrypopup2(true);
            }
            else if (callback.status === 'success' && callback.message === 'Delete Request Already Present' && callback.consentVerified === false) {
                setotpbox(true);
                setremoverequestbtn(true);
                setreqid(callback.deleteRequestId)
                setconfirmdeletepopup(true);
                // sendotp(phoneNumber, (callback) => {
                //     console.log("callback", callback);
                // })
            }
            else if (callback.status === 'success' && callback.message === 'Delete Request Saved Successfully') {
                setotpbox(true);
                setremoverequestbtn(true);
                setreqid(callback.deleteRequestId)
                console.log("clback",callback.deleteRequestId)
                setconfirmdeletepopup(true);
               
            }
        })
    }
    const resendfn = () => {
        sendotp(phoneNumber, (callback) => {
            if(callback === 'success'){

                console.log("callback", callback);
                setconfirmdeletepopup(false);
            }else{
                setsorrypopup3(true);
            }
            })

    }

    const verifyotpfn = () => {
        let otp = "";
        let digit1 = document.getElementById('digit-1').value;
        let digit2 = document.getElementById('digit-2').value;
        let digit3 = document.getElementById('digit-3').value;
        let digit4 = document.getElementById('digit-4').value;
        let digit5 = document.getElementById('digit-5').value;

        if (!(digit1 && digit2 && digit3 && digit4 && digit5)) {
            let elem = document.getElementById("error");
            elem.style.display = "block";

            setTimeout(() => {
                elem.style.display = "none";
            }, 1000)
            return;
        }

        otp = digit1 + digit2 + digit3 + digit4 + digit5;
        
        console.log("opt", otp);
        if(reqid !== '' && otp !== '' && phoneNumber !==''){

            verifyotp(reqid, otp, phoneNumber, (callback) => {
                console.log("callback", callback)
            if(callback === 'success'){
                setverifybtn(false);
                setotpsuccessmessage(true);
                setTimeout(() => {
                    setdeleterequestplacedpopup(true);
                }, 1500);
                setotpbox(false);
               
            }
            else{
                setsorrypopup3(true);
            }
            
        })
    }else if(otp === '' || otp === null){
        setenterotp(true);
    }
    else{
        setsorrypopup3(true)
    }

    }
    const closeiconfn =()=>{
        setdeleterequestplacedpopup(false);
        setTimeout(() => {
            window.location.reload();
          }, 1000);
    }

    return (
        <>
            <Navbar2 />
            <div className="support">
                <div className="container">
                    <h5>Schedule Data Deletion Request</h5>
                    <div className='heads1 d-flex p-2'>
                        <img src={iicon} className="me-2" alt="icon" />
                        <h6>Customer with active loan cannot place delete request.</h6>
                    </div>

                    <div className="formbox px-3 py-4 mt-4">
                        <h5>Enter information</h5>
                        <hr />

                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label style={{ fontSize: '14px', fontWeight: '400', marginBottom: '10px' }}>Registered Email ID</label>
                                <input
                                    type="email"
                                    name="email"
                                    disabled={otpbox === true ? 'disabled' : ''}
                                    style={{ borderRadius: '10px' }}
                                    value={email}
                                    onChange={(e) => handleemailchnage(e)}
                                    className="form-control"
                                    placeholder="Enter Registered Email ID"
                                />

                                {
                                    emailerrormessage ?


                                        <div className='form-text text-danger'>
                                            <img src={Erroricon} alt="Error_icon" style={{ marginRight: '15px', display: 'inline' }} />
                                            <h5 style={{ fontSize: '13px', display: 'inline' }}>Email id does not exists. Please enter the registered Email Id</h5>
                                        </div>
                                        :
                                        ''
                                }

                            </div>
                            <div className="col-md-4 mb-3">
                                <label style={{ fontSize: '14px', fontWeight: '400', marginBottom: '10px' }}>Registered Mobile Number</label>
                                <div class="input-group" style={{ borderRadius: '10px' }}>
                                    <span class="input-group-text" id="basic-addon1" style={{ backgroundColor: 'white' }}> +91</span>

                                    <input
                                        type="number"
                                        name="firstName"
                                        disabled={otpbox === true ? 'disabled' : ''}
                                        value={phoneNumber}
                                        onChange={(e) => handlePhoneNumberChange(e)}
                                        className="form-control"
                                        placeholder="Enter Registered Mobile Number"
                                    />
                                </div>
                                {
                                    numbererrormessage ?

                                        <div className='form-text text-danger'>
                                            <img src={Erroricon} alt="Error_icon" style={{ marginRight: '15px', display: 'inline' }} />
                                            <h5 style={{ fontSize: '13px', display: 'inline' }}>The number associated with this Email id does not match with the number provided by you.</h5>
                                        </div>
                                        : ''
                                }
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label style={{ fontSize: '14px', fontWeight: '400', marginBottom: '10px' }}>Data to Delete</label>
                                <select
                                    className="form-select"
                                    name="roleId"
                                    value={deletedropdown}
                                    placeholder="Select data to delete"
                                    disabled={otpbox === true ? 'disabled' : ''}
                                    onChange={(e) => changeHandlerdropdown(e.target.value)}
                                    style={{ borderRadius: '10px' }}
                                >
                                    <option value="">Select data to delete</option>
                                    <hr />
                                    <option value="Data">
                                        Delete Device Data (My Location, SMS, Contacts, Installed Apps,
                                        Device data (including IMEI, IP etc))
                                    </option>
                                    <option value="Account">Delete Account</option>
                                </select>

                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-8 mb-3">
                                <Checkbox
                                    onClick={() => handleSelect()}
                                    sx={{
                                        color: "rgba(0, 0, 0, 0.1)",

                                        "&.Mui-checked": {
                                            color: "rgba(0, 108, 103, 1)",
                                        },
                                        "& .MuiSvgIcon-root": { fontSize: 25 },

                                    }}

                                    id="checkbox"

                                />
                                <label className="mx-1 entry" htmlFor="checkbox" style={{ cursor: 'pointer', fontWeight: '400', fontSize: '14px' }}>
                                    I hereby provide my consent to fetch Bureau Data.
                                </label>
                            </div>
                        </div>

                        {
                            otpbox ?

                                <div className="row">
                                    <div className="col-md-7 mb-5 p-3 ms-3" style={{ border: '1px solid rgba(1, 74, 148, .2)', borderRadius: '12px' }}>
                                        <h5 style={{ fontSize: '14px', fontWeight: '400' }}>Enter Verification code we just sent on your <span style={{ fontWeight: '600' }}> Registered Email id & Registered Mobile number</span></h5>

                                        <div className="row mt-2" style={{ textAlign: 'center' }}>
                                            <div className="col-xl-7 col-lg-10 col-md-12 col-sm-12 col-xs-12 mt-2">
                                                <div className="otpInputGroup">
                                                    <input className="otpDigit" id="digit-1" onInput={handleOTP} onPaste={handlePaste} onKeyDown={handleOTP} type="text" maxLength={1} inputMode="numeric" placeholder='0' />
                                                    <input className="otpDigit" id="digit-2" onInput={handleOTP} onKeyDown={handleOTP} type="text" maxLength={1} inputMode="numeric" placeholder='0' />
                                                    <input className="otpDigit" id="digit-3" onInput={handleOTP} onKeyDown={handleOTP} type="text" maxLength={1} inputMode="numeric" placeholder='0' />
                                                    <input className="otpDigit" id="digit-4" onInput={handleOTP} onKeyDown={handleOTP} type="text" maxLength={1} inputMode="numeric" placeholder='0' />
                                                    <input className="otpDigit" id="digit-5" onInput={handleOTP} onKeyDown={handleOTP} type="text" maxLength={1} inputMode="numeric" placeholder='0' />
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 mt-2">
                                                {
                                                    
                                                    // <button type='submit' style={{
                                                    //     color: 'white', fontWeight: '700', fontSize: '14px', borderRadius: '10.7px', backgroundColor: 'rgba(0, 108, 103, .5)', boxShadow: '0px 0px 0px 8.55813980102539px rgba(255, 255, 255, 0.15)'
                                                    //     , padding: '15px 35px', border: '0'
                                                    // }}  onClick={() => verifyotpfn()}
                                                    // >Verify</button>
                                                    // :
                                                    <button type='submit' className="Activebtn" style={{
                                                        color: 'white', fontWeight: '700', fontSize: '14px', borderRadius: '10.7px', backgroundColor: 'rgba(0, 108, 103, 1)', boxShadow: '0px 0px 0px 8.55813980102539px rgba(255, 255, 255, 0.15)'
                                                        , padding: '15px 35px', border: '0'
                                                    }} onClick={() => verifyotpfn()}
                                                    >Verify</button>
                                                }


                                            </div>
                                            <div className="col-xl-3 col-lg-3 col-md-5 col-sm-12 col-xs-12 mt-2">
                                                <button type='submit' className='Activebtn2' style={{
                                                    color: 'rgba(0, 108, 103, 1)', fontWeight: '700', fontSize: '14px', borderRadius: '10.7px', backgroundColor: 'rgba(0, 108, 103, .2)', boxShadow: '0px 0px 0px 8.55813980102539px rgba(255, 255, 255, 0.15)'
                                                    , padding: '15px 25px', border: '1px solid rgba(0, 108, 103, 1)'
                                                }} onClick={() => resendfn()}
                                                >resend OTP</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : ''
                        }

                        {
                            otpsuccessmessage ?

                                <div className="row">
                                    <div className="col-md-4 mb-3">

                                        <div className='text-success' style={{
                                            color: 'white', fontWeight: '600', fontSize: '16px', borderRadius: '10.7px', boxShadow: '0px 0px 0px 8.55813980102539px rgba(255, 255, 255, 0.15)', padding: '15px 20px', border: '0', backgroundColor: 'rgba(0, 108, 103, .2)'
                                        }}
                                        ><img src={tickicon} alt="tick_icon" style={{ marginRight: '20px' }} /> OTP  is Verified Successfully!</div>
                                    </div>
                                </div>
                                :
                                ''
                        }

                        {
                            removerequestbtn ? '' :

                                <div className="row">
                                    <div className="col-md-4 mb-3">
                                        {
                                            email !== '' && email !== undefined && phoneNumber !== '' && selectedcheckbox !== false && deletedropdown !== '' ?
                                                (
                                                    <button type='submit' className="Activebtn" style={{
                                                        color: 'white', fontWeight: '700', fontSize: '14px', borderRadius: '10.7px', backgroundColor: 'rgba(0, 108, 103, 1)', boxShadow: '0px 0px 0px 8.55813980102539px rgba(255, 255, 255, 0.15)'
                                                        , padding: '15px 35px', border: '0'
                                                    }} onClick={() => handleOTPRequest()}
                                                    >Request OTP</button>
                                                )
                                                :
                                                (
                                                    <button type='submit' className='Inactivebtn' style={{
                                                        color: 'white', fontWeight: '700', fontSize: '14px', borderRadius: '10.7px', boxShadow: '0px 0px 0px 8.55813980102539px rgba(255, 255, 255, 0.15)', padding: '15px 35px', border: '0', backgroundColor: 'rgba(0, 108, 103, .5)'
                                                    }}
                                                    >Request OTP</button>
                                                )
                                        }

                                    </div>
                                </div>
                        }
                    </div>
                </div>
                {deleterequestplacedpopup ?
                    <div className="modal">
                        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "25%" }}>
                            <div className="modal-content" style={{ background: 'linear-gradient(90deg, #003844 0%, #046E69 78.94%)' }}>
                                <div className="modal-header">
                                    <h6 className="modal-title mx-auto">
                                        {/* Edit agent details */}
                                    </h6>

                                    <button
                                        data-dismiss="modal"
                                        className="btn"
                                        onClick={() => closeiconfn()}
                                    >
                                        <span className="font-weight-bold text-white">X</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className=" text-center mt-2">
                                        <img src={tickicon2} alt="tick_icon" />
                                        <h5 className='mt-5 text-white' style={{ fontSize: '18px', fontWeight: '600', lineHeight: '24px' }}>Your Request to Delete Account is Placed.</h5>


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
                {confirmdeletepopup ?
                    <div className="modal">
                        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "40%" }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 className="modal-title mx-auto">
                                        {/* Edit agent details */}
                                    </h6>

                                    <button
                                        data-dismiss="modal"
                                        className="btn"
                                        onClick={() => setconfirmdeletepopup(false)}
                                    >
                                        <span className="font-weight-bold text-success">X</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className=" text-center">
                                        <h5 className='mt-2 mb-3' style={{ fontSize: '16px', fontWeight: '600' }}>Are you sure you want to delete your data?</h5>

                                        <div className="btnss">

                                            <button type='submit' className='Activebtn2 me-3' style={{
                                                color: 'rgba(0, 108, 103, 1)', fontWeight: '700', fontSize: '14px', borderRadius: '10.7px', backgroundColor: 'rgba(0, 108, 103, .2)', boxShadow: '0px 0px 0px 8.55813980102539px rgba(255, 255, 255, 0.15)'
                                                , padding: '13px 45px', border: '1px solid rgba(0, 108, 103, 1)', display: 'inline'
                                            }} onClick={() => setconfirmdeletepopup(false)}
                                            >Nevermind</button>

                                            <button type='submit' className="Activebtn" style={{
                                                color: 'white', fontWeight: '700', fontSize: '14px', borderRadius: '10.7px', backgroundColor: 'rgba(0, 108, 103, 1)', boxShadow: '0px 0px 0px 8.55813980102539px rgba(255, 255, 255, 0.15)'
                                                , padding: '13px 45px', border: '0'
                                            }} onClick={()=>resendfn()}
                                            >Confirm</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
                {sorrypopup2 ?
                    <div className="modal">
                        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "25%" }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 className="modal-title mx-auto">
                                        {/* Edit agent details */}
                                    </h6>

                                    <button
                                        data-dismiss="modal"
                                        className="btn"
                                        onClick={() => setsorrypopup2(false)}
                                    >
                                        <span className="font-weight-bold text-danger">X</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="img text-center">
                                        <img src={Erroricon2} alt="error_icon" />

                                        <h5 className='text-danger mt-2' style={{ fontSize: '16px', fontWeight: '700' }}>Delete Request Already Present</h5>

                                        {/* <h5 className="mt-3" style={{ fontSize: '14px', fontWeight: '400' }}>You have an Active Loan Account with us due to which we cannot delete your account for Legitimate reasons.</h5> */}

                                        <button type='submit' className='Activebtn2' style={{
                                            color: 'rgba(0, 108, 103, 1)', fontWeight: '700', fontSize: '14px', borderRadius: '10.7px', backgroundColor: 'rgba(0, 108, 103, .2)', boxShadow: '0px 0px 0px 8.55813980102539px rgba(255, 255, 255, 0.15)'
                                            , padding: '13px 45px', border: '1px solid rgba(0, 108, 103, 1)'
                                        }} onClick={() => setsorrypopup2(false)}
                                        >OK</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
                {sorrypopup3 ?
                    <div className="modal">
                        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "25%" }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 className="modal-title mx-auto">
                                        {/* Edit agent details */}
                                    </h6>

                                    <button
                                        data-dismiss="modal"
                                        className="btn"
                                        onClick={() => setsorrypopup3(false)}
                                    >
                                        <span className="font-weight-bold text-danger">X</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="img text-center">
                                        <img src={Erroricon2} alt="error_icon" />

                                        <h5 className='text-danger mt-2' style={{ fontSize: '15px', fontWeight: '700' }}> Please Try Again later!</h5>

                                        {/* <h5 className="mt-3" style={{ fontSize: '14px', fontWeight: '400' }}>You have an Active Loan Account with us due to which we cannot delete your account for Legitimate reasons.</h5> */}

                                       
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
                {enterotp ?
                    <div className="modal">
                        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "25%" }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 className="modal-title mx-auto">
                                        {/* Edit agent details */}
                                    </h6>

                                    <button
                                        data-dismiss="modal"
                                        className="btn"
                                        onClick={() => setenterotp(false)}
                                    >
                                        <span className="font-weight-bold text-danger">X</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="img text-center">
                                       

                                        <h5 className="mt-3" style={{ fontSize: '17px', fontWeight: '400' }}>Please Enter OTP</h5>

                                      
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
                {sorrypopup ?
                    <div className="modal">
                        <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "25%" }}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 className="modal-title mx-auto">
                                        {/* Edit agent details */}
                                    </h6>

                                    <button
                                        data-dismiss="modal"
                                        className="btn"
                                        onClick={() => setsorrypopup(false)}
                                    >
                                        <span className="font-weight-bold text-danger">X</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="img text-center">
                                        <img src={Erroricon2} alt="error_icon" />

                                        <h5 className='text-danger mt-2' style={{ fontSize: '22px', fontWeight: '700' }}> Sorry!</h5>

                                        <h5 className="mt-3" style={{ fontSize: '14px', fontWeight: '400' }}>You have an Active Loan Account with us due to which we cannot delete your account for Legitimate reasons.</h5>

                                        <button type='submit' className='Activebtn2' style={{
                                            color: 'rgba(0, 108, 103, 1)', fontWeight: '700', fontSize: '14px', borderRadius: '10.7px', backgroundColor: 'rgba(0, 108, 103, .2)', boxShadow: '0px 0px 0px 8.55813980102539px rgba(255, 255, 255, 0.15)'
                                            , padding: '13px 45px', border: '1px solid rgba(0, 108, 103, 1)'
                                        }} onClick={() => setsorrypopup(false)}
                                        >OK</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
            </div>
            <Footer2 />
        </>
    )
}
