import './App.css';
import './index.scss';
import { Routes, Route } from 'react-router-dom';
import FAQ from '../src/components/MainPages/FAQ';
import PrivacyPolicy from '../src/components/MainPages/PrivacyPolicyPage';
import TermsCondition from '../src/components/MainPages/TermsConditionPage';
import GreivenceRedressal from '../src/components/MainPages/GreivenceRedressal';
import Support from '../src/components/MainPages/Support';
import Homepage from './FirstPageComponents/Homepage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route index element={<Homepage />}></Route>
        <Route path={'/home/FAQ'} element={<FAQ />}></Route>
        <Route path={'/home/PrivacyPolicy'} element={<PrivacyPolicy />}></Route>
        <Route path={'/home/TermsCondition'} element={<TermsCondition />}></Route>
        <Route path={'/home/GreivenceRedressal'} element={<GreivenceRedressal />}></Route>
        <Route path={'/home/Support'} element={<Support />}></Route>
      </Routes>
    </div>
  );
}

export default App;
