import { Link } from 'react-router-dom'
import WWO1 from '../assets/wwo-1.png'
import WWO2 from '../assets/wwo-2.png'
import WWO3 from '../assets/wwo-3.png'
import WWO4 from '../assets/wwo-4.png'
import WWO5 from '../assets/wwo-5.png'
import WWO6 from '../assets/wwo-6.png'
// import LS8 from '../assets/LS-8.png'
import LS8 from '../assets/LS-8.webp'
// import LS9 from '../assets/LS-9.png'
import LS9 from '../assets/LS-9.webp'
// import LS12 from '../assets/LS-12.png'
import LS12 from '../assets/LS-12.webp'
import Hero1 from '../assets/bannerimage.webp'
// import Hero1 from '../assets/bannerimage.png'
import ArhamFinanceLOGO from '../assets/ArhamFinanceLOGO.png'
import Navbar from '../components/Navbar/navbar'
import Footer from '../components/Footer/Footer'
import CardCurve from '../assets/curves-lines.png'
import react, { useEffect, useState } from 'react';
import { BsDatabaseLock, BsPersonBadge } from 'react-icons/bs'
import { AiOutlineMobile, AiOutlineCheckCircle, AiOutlineFileProtect, AiOutlineMail, } from 'react-icons/ai'
import { GiMoneyStack } from 'react-icons/gi'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import EmiCalculator from './EmiCalculator'
import Faq from './FaqComponent';
import { FaChartLine, FaHdd, FaBullseye, FaListAlt } from 'react-icons/fa'
import '../css/Homepage.scss';

function Homepage() {
    const [activeStep, setActiveStep] = useState(1);

    useEffect(() => {
        const textElements = document.querySelectorAll('.text1, .text2, .text3');
        const circleElements = document.querySelectorAll('.circle');

        const handleScroll = () => {
            textElements.forEach((textElement, index) => {
                const rect = textElement.getBoundingClientRect();
                if (rect.top >= 0 && rect.bottom <= (window.innerHeight * 1.1)) {
                    setActiveStep(index + 1);
                    console.log(activeStep);
                }
            });
        };



        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <>
            <Navbar />
            <div className="banner-area circle-shape auto-height ">
                <div className="shape-left-bottom">
                </div>
                <div className="container">
                    <div className="content-box">
                        <div className="row align-center">
                            <div className="col-xl-7 col-lg-6 col-xs-12 info ">
                                <h2>Get Money in Minutes</h2>
                                <h6>Quick and Accessible Financial Solutions for Your Needs!</h6>
                                {/* <Link className="btn btn-lg border">Apply now</Link> */}
                                <a href="https://play.google.com/store/apps/details?id=com.credfin.money" target="_blank" className="btn btn-lg border">
                                    Apply Now
                                </a>
                            </div>
                            <div className="col-xl-5 col-lg-6 col-xs-12">
                                <div className="thumb-innner">
                                    <img src={Hero1} alt="banner_img" loading="lazy"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="tabs">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-lg-3 col-sm-6 col-xs-12 mt-4">
                            <div className="itemsfirst">


                                <div className="row justify-content-center">
                                    <div className="icon position-relative">
                                        <div className="iconWrapper">
                                            <FaChartLine className="icon" />
                                        </div>
                                    </div>
                                    <h4 className=''>1,00,000+</h4>
                                    <h5>LOAN PROCESSED</h5>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-3 col-sm-6 col-xs-12 mt-4">
                            <div className="itemsfirst">


                                <div className="row justify-content-center">
                                    <div className="icon position-relative">
                                        <div className="iconWrapper">
                                            <FaBullseye className="icon" />
                                        </div>
                                    </div>
                                    <h4 className=''>4 Million+</h4>
                                    <h5>APP DOWNLOADS</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12 mt-4">
                            <div className="itemsfirst">


                                <div className="row justify-content-center">
                                    <div className="icon position-relative">
                                        <div className="iconWrapper">
                                            <FaHdd className="icon" />
                                        </div>
                                    </div>
                                    <h4 className=''>220 Crores+</h4>
                                    <h5>AMOUNT DISBURSED</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-xs-12 mt-4">
                            <div className="itemsfirst">


                                <div className="row justify-content-center">
                                    <div className="icon position-relative">
                                        <div className="iconWrapper">
                                            <FaListAlt className="icon" />
                                        </div>
                                    </div>
                                    <h4 className=''>70%</h4>
                                    <h5>REPEAT USERS</h5>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div> */}


            <div className="about-features-area " id='AU'>
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-5 col-xs-12 columnpadding" >
                            <div className="headingabout">
                                <h5 className='fw-bold'>About Us</h5>
                                <h2 className='mb-4'>Welcome to CredFin!</h2>
                            </div>
                        </div>

                        <div className="col-lg-7 col-xs-12">
                            <div className="textabout">
                                <h6 className='para mb-3'>
                                    We understand the importance of quick and accessible financial solutions, and our app is designed to provide just that. With our user-friendly interface and advanced features, you can get the funds you need quickly.
                                    <br /><br />
                                    Our app is built to support multiple platforms, ensuring that you can access it anytime, anywhere. Whether you prefer to use it on the web or through your mobile device, we've got you covered.
                                    <br />

                                    We have taken into account the crucial attributes that users like you would expect from a loan app. Our app offers convenience, speed, and robust data protection, ensuring a seamless and secure borrowing experience.

                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="whatweoffer" id="WWO">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="offerheading">
                                <h5>WHAT WE OFFER</h5>
                                <h4>Welcome to Credfin</h4>
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: '60px' }}>
                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <div className="wwo-1 text-center">
                                <img src={WWO1} alt="what_we_offer_img" width={'20%'} />
                                <h6 className="h6">Instant Approval</h6>
                                <h6>Experience the convenience of instant approvals, ensuring swift access to the funds you need without unnecessary delays.</h6>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <div className="wwo-1 text-center">
                                <img src={WWO2} alt="what_we_offer_img" width={'20%'} />
                                <h6 className="h6">Flexible EMI's</h6>
                                <h6>Tailor your repayment journey with our flexible EMI options, providing you the freedom to manage your loan on terms that align with your financial goals.</h6>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <div className="wwo-1 text-center">
                                <img src={WWO3} alt="what_we_offer_img" width={'20%'} />
                                <h6 className="h6">Intutive Interface</h6>
                                <h6>Navigate effortlessly through our lending platform with an intuitive interface designed for a user-friendly and seamless experience.</h6>
                            </div>
                        </div>


                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <div className="wwo-1 text-center">
                                <img src={WWO4} alt="what_we_offer_img" width={'20%'} />
                                <h6 className="h6">Direct Fund Disbursement</h6>
                                <h6>Experience the convenience of direct fund disbursement, ensuring that approved loans are swiftly transferred to your account without intermediaries.</h6>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <div className="wwo-1 text-center">
                                <img src={WWO5} alt="what_we_offer_img" width={'20%'} />
                                <h6 className="h6">Eligibility Checks</h6>
                                <h6>Streamlined eligibility checks make the borrowing process efficient, ensuring you quickly discover your loan eligibility without unnecessary complexities.</h6>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-xs-12">
                            <div className="wwo-1 text-center">
                                <img src={WWO6} alt="what_we_offer_img" width={'20%'} />
                                <h6 className="h6">Security & Data Protection</h6>
                                <h6>Rest assured with top-notch security and data protection measures in place, safeguarding your personal and financial information throughout your interaction with our platform.</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="Loan-steps-third pb-5" id="LS">
                <div className="container">
                    <div className="row pb-4">
                        <div className="col">
                            <div className="heading">
                                <h3 className='fw-bold h3'>LOAN STEPS</h3>
                                <h3 className='fw-bold'>Welcome to Credfin</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-4">
                        <div className="col-6 text-center" style={{ paddingRight: '0px', marginTop: '50px', marginBottom: '50px' }}>
                            {
                                (activeStep === 1) ?
                                    (
                                        <div className="imagess">
                                            <div className="images me-3">
                                                <img src={LS8} width={'400px'} alt="Loan_steps_image" />
                                            </div>
                                        </div>
                                    )
                                    :
                                    (activeStep === 2) ?
                                        (
                                            <div className="imagess" >
                                                <div className="images me-3">
                                                    <img src={LS12} width={'250px'} alt="Loan_steps_image" />
                                                </div>
                                            </div>
                                        )
                                        :
                                        (activeStep === 3) ?
                                            (
                                                <div className="imagess" >
                                                    <div className="images me-3" style={{ paddingTop: '300px' }}>
                                                        <img src={LS9} width={'400px'} alt="Loan_steps_image" />
                                                    </div>
                                                </div>
                                            )
                                            :
                                            ''
                            }
                        </div>
                        <div className="col-6 columntext" style={{ paddingLeft: '0px' }}>
                            <div className="texts">
                                <div className={`text1 ${(activeStep === 1 || activeStep > 1) ? 'activetext' : ''}`} >
                                    <div className={`box ${(activeStep === 1) ? 'activebox' : ''}`}>
                                        <h6 className='h6'>1. Download the app & Create Account</h6>
                                        <h6>
                                            Download our app today and embark on a seamless financial journey by easily creating your account, and putting control over your finances at your fingertips.
                                        </h6>
                                    </div>
                                </div>
                                <div className={`text2 ${(activeStep === 2 || activeStep > 2) ? 'activetext' : ''}`} >
                                    <div className={`box ${(activeStep === 2) ? 'activebox' : ''}`}>
                                        <h6 className='h6'>2. Fill Loan Application & Submit Required Documents</h6>
                                        <h6 >Streamline your borrowing experience by filling out the loan application and submitting the required documents with ease, paving the way for a quick and efficient approval process.</h6>
                                    </div>
                                </div>
                                <div className={`text3 ${(activeStep === 3 || activeStep > 3) ? 'activetext' : ''}`} >
                                    <div className={`box ${(activeStep === 3) ? 'activebox' : ''}`}>
                                        <h6 className='h6'>3. Application Evaluation, Loan Approval & Disbursement</h6>
                                        <h6>Witness the efficiency of our application evaluation system, providing prompt loan approval and ensuring the rapid disbursement of funds to meet your financial needs.
                                        </h6>
                                    </div>
                                </div>
                                <div className='btnss' style={{ paddingLeft: '40px' }}>
                                    {/* <button className="buttonapply">Apply Now</button> */}
                                    <a href="https://play.google.com/store/apps/details?id=com.credfin.money" target="_blank" className="buttonapply">
                                    Apply Now
                                </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="Loan-steps-fourth pt-5 pb-5 d-none" id="LS">
                <div className="container">
                    <div className="row pb-4">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="heading">
                                <h3 className='fw-bold h3'>LOAN STEPS</h3>
                                <h3 className='fw-bold'>Welcome to Credfin</h3>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="rowfirst">
                                <h5>Download the app & Create Account</h5>

                            </div>
                        </div>
                        <div className="col-12">
                            <div className="rowfirstimg">
                                <img src={LS8} alt="Loan_step_image" width={'50%'} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="rowsecond">
                                <h5>Fill Loan Application & Submit Requirement Documents</h5>

                            </div>
                        </div>
                        <div className="col-12">
                            <div className="rowsecondimg">
                                <img src={LS12} alt="Loan_step_image" width={'40%'} />
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12">
                            <div className="rowthird">
                                <h5>Application Evaluation, Loan Approval & Disbursement</h5>

                            </div>
                        </div>
                        <div className="col-12">
                            <div className="rowthirdimg">
                                <img src={LS9} alt="Loan_step_image" width={'50%'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services-area pt-5 " id="WA">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="site-heading">
                                <h3 className='fw-bold heads'>WHY APPLY</h3>
                                <h3 className='fw-bold'>Welcome to Credfin</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-lg">
                    <OwlCarousel className='owl-theme' loop dots={false} responsiveClass={true} responsive={
                        {
                            0: {
                                items: 1,
                                dots: true,
                                nav: false,
                                loop: true,
                                autoplay: true,
                                autoplayTimeout: 3000,
                            },
                            600: {
                                items: 2,
                                dots: true,
                                nav: false,
                                loop: true,
                                autoplay: true,
                                autoplayTimeout: 3000,
                            },
                            1000: {
                                items: 3,
                                dots: true,
                                nav: false,
                                loop: true,
                                autoplay: true,
                                autoplayTimeout: 3000,
                            }
                        }
                    }>
                        <Card Comp={AiOutlineMobile} link='#' clr1={"#4154F1"} clr2={"#011CFF"} title={"User-Friendly Interface"} content={"Our App is designed with a simple and intuitive interface, ensuring you can easily navigate the application process."} />
                        <Card Comp={AiOutlineCheckCircle} link='#' clr1={"#63BC6F"} clr2={"#A0F8AC"} title={"Quick Approvals"} content={"Our App enables you to apply for personal loans and receive quick approvals, minimizing the waiting time for accessing funds."} />
                        <Card Comp={GiMoneyStack} link='#' clr1={"#4154F1"} clr2={"#6C7CFF"} title={"Direct Fund Disbursement"} content={"Once approved, the funds are disbursed directly into your bank account, ensuring immediate access to the required funds."} />
                        <Card Comp={AiOutlineFileProtect} link='#' clr1={"#F84E77"} clr2={"#FFA3B9"} title={"Eligibility Check"} content={"Our app allows you to check your eligibility for a loan by entering your personal and financial information, helping you understand your borrowing capacity."} />
                        <Card Comp={BsDatabaseLock} link='#' clr1={"#FC573C"} clr2={"#FB9281"} title={"Security & Data Protection"} content={"To ensure the safety of user information, our app employs robust data protection measures and adheres to legal regulations to safeguard personal and financial data."} />
                        <Card Comp={BsPersonBadge} link='#' clr1={"#63BC6F"} clr2={"#A0F8AC"} title={"Personalization"} content={"Our app offers personalised loan options based on your profile, ensuring you receive suitable loan offers tailored to your needs."} />
                    </OwlCarousel>
                </div>
            </div>

            <EmiCalculator />

            <div className="LendingPartner mt-5 mb-5">
                <div className="container">
                    <div className="block1">
                        <div className="row text-center">
                            <div className="col">
                                <h4>Lending Partner</h4>
                            </div>
                        </div>
                        <div className="row text-center mt-2 px-5">
                            <div className="col px-5">
                                <p>
                                    Our platform - CredFin, helps borrowers and NBFCs/banks complete loan transactions. Every loan application is reviewed and authorized by NBFCs or banks that are registered with the RBI, and all information is disclosed upfront.
                                </p>
                            </div>
                        </div>
                        <div className="row rows1 mt-4">
                            <div className="col text-center d-flex justify-content-center">
                                <div className="imgs">
                                    <img src={ArhamFinanceLOGO} alt="arham_logo" width={'50%'} />
                                </div>
                                <div className="btnss pt-4">
                                    <h5 className='mb-4'>Arham Finance & Investment Services Ltd.</h5>
                                    <a className='arhamlink' href="https://arhamfinance.com/" target="_blank">Arham Finance →</a>
                                </div>
                                {/* <div className="btnss pt-4">
                                    <h5>Arham Finance And Investment Services Limited</h5>
                                    <button className='button10 mt-3'><a href="https://arhamfinance.vercel.app/" target="_blank">Arham Finance →</a></button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Faq />
            <Footer />
        </>
    )
}

export default Homepage


function Card({ Comp, link = "#", title, content, clr1 = "#63BC6F", clr2 = "#A0F8AC" }) {

    return (
        <div className="item card mb-5">
            <img className='card-curve-lines' src={CardCurve} alt="card_curve_image" />
            <div className="icon" style={{ background: `linear-gradient(to bottom right, ${clr1}, ${clr2})` }}>
                {<Comp style={{ fontSize: "50px", color: "white" }} />}
            </div>
            <div className="info">
                <h4 className='card-title'><a href={link}>{title}</a></h4>
                <p className='card-text'>{content}</p>
                <a className='card-link' href={link}>Read More</a>
            </div>
        </div>
    )
}
