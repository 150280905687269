import '../App.css';
import '../index.scss';
import OurRoutes from './Routes';
import * as Routers from './Routers';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Suspense } from 'react';
function AppRouter() {
    return (
        <Router>
            <Suspense>
                <Routes>
                    <Route path={OurRoutes.Homepage} element={<Routers.Homepage />} />
                    <Route path={OurRoutes.FAQ} element={<Routers.FAQ />} />
                    <Route path={OurRoutes.PrivacyPolicy} element={<Routers.PrivacyPolicy />} />
                    <Route path={OurRoutes.TermsCondition} element={<Routers.TermsCondition />} />
                    <Route path={OurRoutes.GreivenceRedressal} element={<Routers.GreivenceRedressal />} />
                    <Route path={OurRoutes.Support} element={<Routers.Support />} />
                    <Route path={OurRoutes.CancellationPage} element={<Routers.CancellationPage />} />

                    <Route path={OurRoutes.Aboutus} element={<Routers.Aboutus />} />
                    <Route path={OurRoutes.Contactus} element={<Routers.Contactus />} />
                    <Route path={OurRoutes.Message} element={<Routers.Message />} />
                    <Route path={OurRoutes.OurPartner} element={<Routers.OurPartner />} />
                </Routes>
            </Suspense>
        </Router>
    );
}

export default AppRouter;
