import axios from "axios";
import { APIS } from "./apiFactory";


export const dataDeleteRequest = (data,callBack, error) => {
    axios
      .post(APIS.DATA_DELETE_REQUEST, data)
      .then((res) => {
        callBack(res.data);
      })
      .catch((e) => {
        // error(e.res.data)
      });
  };
export const sendotp = (mobilenumber,callBack, error) => {
    axios
      .post(APIS.SEND_OTP_MOBILE_NUMBER + mobilenumber)
      .then((res) => {
        callBack(res.data);
      })
      .catch((e) => {
        // error(e.res.data)
      });
  };
export const verifyotp = (id,otp,mobilenumber,callBack, error) => {
    // &otp&mobileNumber
    axios
      .post(APIS.VERIFY_SEND_OTP + id + "&otp=" + otp + "&mobileNumber=" + mobilenumber)
      .then((res) => {
        callBack(res.data);
      })
      .catch((e) => {
        // error(e.res.data)
      });
  };
