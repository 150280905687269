
// export const BASE_URL_2 = "http://192.168.1.11:8070"
export const BASE_URL_2 = 'https://backend.credfin.money/'

export const APIS = {
    DATA_DELETE_REQUEST: BASE_URL_2 + 'dataDeleteRequest',
    SEND_OTP_MOBILE_NUMBER: BASE_URL_2 + 'sendConsentOTPForDataDelete?mobileNumber=',
    VERIFY_SEND_OTP  : BASE_URL_2 + 'verifyConsentOTPForDataDelete?deleteRequestId=',
    
}
