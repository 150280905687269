import React from 'react'
import Navbar2 from '../Navbar/navbar2';
import '../../css/Policy.scss';
import Footer2 from '../Footer/Footer2';
export default function PrivacyPolicyPage() {
    return (
        <>
            <Navbar2 />
            <div className="privacypolicy mb-5">
                <div className="container">
                    <div className="row">
                        <h2 style={{ paddingLeft: '32px', borderLeft: '6px solid #FF6337' }}>Privacy Policy</h2>
                        <h4>Please read the below policy before using the Credfin App</h4>
                        <p>Credfin services PRIVATE LIMITED, whose registered office is  2nd Floor, Room No 204, H-4866/1, 24, Ansari Road, Daryaganj, New Delhi, Central Delhi, Delhi - 110002 (hereinafter shall be referred to as "Credfin" or "Company" or "us" or "we" are the owner of the mobile application by the name Credfin ("App") and website at www.credfin.money ("Website") (App and Website are hereinafter together referred to as the "Platform").</p>
                        <p>In order to process your loan requests, and to ultimately provide a loan to you we need to collect various data and information from you</p>
                        <p>By visiting our website, www.Credfin.in ("Website") or the "Platform") you agree to be bound by the terms and conditions of this Privacy Policy. By mere access to the Platform or any part thereof, you expressly consent to Credfin services Private Limited ("we" or "our" or "us" ) use and disclosure of your personal information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms of Use of the Platform.</p>
                        <p>If you do not agree to this Policy or any part thereof, please do not use or access our Platform or any part thereof.</p>


                        <h3>GRIEVANCE OFFICER CONTACT INFORMATION</h3>
                        <p>In accordance with Information Technology Act 2000 and rules made thereunder, the name and contact details of the Grievance Officer are provided below:</p>
                        <p>
                            {/* Name: Ravinder Sharma */}
                            <br />
                            Address: 2nd Floor, Room No 204, H-4866/1, 24, Ansari Road, Daryaganj, New Delhi, Central Delhi, Delhi - 110002
                            {/* Address: H-9/1, Block B-1, above Sagar Ratna, Mohan Cooperative Industrial Estate, Mathura Road, New Delhi - 110044 */}
                            <br />
                            {/* Phone: +918010700600 */}
                            {/* <br /> */}
                            Email: support@credfin.money 
                        </p>

                        <h3>COLLECTION OF PERSONALLY IDENTIFIABLE INFORMATION</h3>
                        <p>When you use our Platform, whether our Website or our App, we collect and store your information which is provided by you from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience and services. This allows us to provide services and features that meet your needs, and to customize our Platform to make your experience safer and easier and to improve the services provided by us. More importantly, we collect personal information from you that we consider necessary for achieving the aforementioned purpose.</p>
                        <p>In general, you can browse the Website or App without telling us who you are or revealing any personal information about yourself. However, to create an account on the Website or App, you must provide us with certain basic information required to provide customized services. The information we collect from you, inter alia, including:</p>
                        <ol>
                            <li>your full name;</li>
                            <li>email;</li>
                            <li>Residential address;</li>
                            <li>postal code;</li>
                            <li>Organisation Name;</li>
                            <li>Salary details;</li>
                            <li>phone number;</li>
                            <li>Permanent Account Number (PAN);</li>
                            <li>Bank Account Details;</li>
                        </ol>
                        <p><strong>We only collect and store your Profile Picture and Username when you login to our platform using Gmail</strong></p>
                        <p>Where possible, we indicate the mandatory and the optional fields. You always have the option to not provide your personal information by choosing not to use a particular service or feature on the Platform. We also collect other identifiable information (your last 3 months Account statement, last 3 months salary details and transaction histories on the Platform) from you when you set up a free account with us. While you can browse some sections of our Platform without being a registered member as mentioned above, certain activities (such as availing of loans on the Platform) requires registration and for you to provide the above details. The Platform shall clearly display the personal information it is collecting from you, and you have the option to not provide such personal information. However, this will limit the services provided to you on the Platform.</p>

                        <h3>COLLECTION OF OTHER NON-PERSONAL INFORMATION</h3>
                        <p>We automatically track certain information about you based upon your behavior on our Platform. We use this information to do internal research on our users' demographics, interests, and behavior to better understand, protect and serve our users and improve our services. This information is compiled and analyzed on an aggregated basis.<strong> We also collect your Internet Protocol (IP) address and the URL used by you to connect your computer to the internet, etc.</strong> This information may include the URL that you just came from (whether this URL is on our Website or not), which URL you next go to (whether this URL is on our Website or not), your computer browser information, and your IP address.</p>

                        <p>Cookies are small data files that a Website stores on Your computer. We will use cookies on our Website similar to other lending websites/apps and online marketplace websites/apps. Use of this information helps us identify You in order to make our Website more user-friendly. Most browsers will permit You to decline cookies but if You choose to do this it might affect service on some parts of Our Website.</p>

                        <p>We retain this information as necessary to resolve disputes, provide customer support and troubleshoot problems as permitted by law. If you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on the Website, we collect such information into a file specific to you.</p>

                        <h3>COLLECTION OF PERSONAL & SENSITIVE DATA</h3>
                        <p>The information the App collects, and how that information is used, depends on how you manage your privacy controls on your device. When you install the App, we store the information we collect with unique identifiers tied to the device you're using.</p>
                        <p>We collect information from the device when you download and install the App and explicitly seek permissions from YOU to get the required information from the device.</p>
                        <p><i>The information we collect from your device includes the unique ID i.e. IMEI number, information on the operating system, SDK version and mobile network information including carrier name, SIM Serial and SIM Slot, your profile information, list of installed apps, wi-fi information. We collect information about your device to provide automatic updates and additional security so that your account is not used in other people's devices. In addition, the information provides us valuable feedback on your identity as a device holder as well as your device behavior, thereby allowing us to improve our services and provide an enhanced customized user experience to you.</i></p>

                        <p>The key data collected from each permission granted in the device and how this data is used is further detailed below-</p>

                        <div className='table-responsive'>
                            <table className='table '>
                                <tbody>
                                    <tr>
                                        <th>S. NO.</th>
                                        <th>Permission Name</th>
                                        <th>What data is collected</th>
                                        <th>How the data is used</th>
                                    </tr>

                                    <tr>
                                        <td>1.</td>
                                        <td>Camera related permissions</td>
                                        <td>Permission to access the camera is required so that requisite KYC documents can be easily scanned or captured and thus saving time by allowing us to auto-fill relevant data. This will ensure that you are provided with a seamless experience while using our application.</td>
                                        <td>We use the data provided by using camera to analyze your creditworthiness, loan eligibility, check KYC documents, and the terms of your loans..</td>
                                    </tr>
                                    <tr>
                                        <td>2.</td>
                                        <td>Storage related Permissions</td>
                                        <td>Storage permission is required so that your KYC and other relevant documents can be securely downloaded and saved to your phone. These KYC documents can then be easily uploaded for faster completion of your loan application and disbursal. This will help us to ensure that you undergo a seamless experience on our application.
                                            <br />
                                            <i>You will not be able to use the App if you disable this access.</i>
                                        </td>
                                        <td>We use this permission to securely download and save documents to your mobile device so that they can then be easily uploaded for faster completion of your loan application</td>
                                    </tr>
                                    <tr>
                                        <td>3.</td>
                                        <td>Location related permission</td>
                                        <td>Location-related permissions is collected when it is enabled from your mobile device and this permission is required to assess the serviceability of your loan application.
                                            <br />
                                            <i>We collect this data when our App is running in the background of your mobile device. You will not be able to use the App if you disable the location services.</i>
                                        </td>
                                        <td>We use this data taken by granting this permission for verifying the address and undertaking a continuous credit risk assessment. It will expedite the completion of your ICC process along with facilitating better credit access to the customers. It will also help in strengthening security against any unauthorized access to your account. This permission will also be used for providing better customer service and providing Customized offers.</td>
                                    </tr>
                                    <tr>
                                        <td>4.</td>
                                        <td>Phone related permissions</td>
                                        <td>We collect and store your hardware model, build model, RAM Storage, Unique device identifiers like IMEI, serial number, SSAIO, SIM information that includes network operators/rooming state, MNC and MCC Codes WIFI information that includes MAC Address and mobile network information.
                                            <br />
                                            <i>You will not be able to use the App if you disable this access.</i>
                                        </td>
                                        <td>The data collected here is used for Research and Development, for enabling Marketing and Outreach, to uniquely identify the devices and to ensure that no unauthorized device acts on your behalf which will help us in preventing frauds. This also helps in enriching your credit profile and assessing your creditworthiness.</td>
                                    </tr>
                                    <tr>
                                        <td>5.</td>
                                        <td>SMS related permissions</td>
                                        <td>When you grant us access to SMS, we collect all the SMS data present on your mobile device. All the data collected with SMS access are stored to Credfin servers and we do not share this data with any third-party. This data is used as a part of internal assessment only.
                                            <br />
                                            <i>This data may be collected even when the app is not in use. You will not be able to use the App if you disable this access.</i>
                                        </td>
                                        <td>The data collected here is used for loan processing and it enables our team to assess credit worthiness, credit risk and detect any fraud in a better manner resulting in speedy disbursal.</td>
                                    </tr>
                                    <tr>
                                        <td>6.</td>
                                        <td>Installed Application related permissions</td>
                                        <td>We collect and store each installed applications' metadata information which includes the application name, package name, installed time, updated time, version name, and version code of each installed application on your device.
                                            <br />
                                            <i>You will not be able to use the App if you disable this access.</i>
                                        </td>
                                        <td>We use the data colleced here to assess your creditworthiness and provide you with pre-approved customized loan offers.</td>
                                    </tr>
                                    <tr>
                                        <td>7.</td>
                                        <td>User Personal Information related permissions</td>
                                        <td>We collect and store user account data which includes email address and user public profile information like name or photo depending on the platform (Google) used by you to login to the app.
                                        </td>
                                        <td>This information is required as a part of the registration process and to access our service and is also used to auto-populate relevant fields in the course of our opp interface. Our app also collects your mobile number for verification to check the active SIM status of the device, uniquely identify you, and prevent fraud and unauthorized access.</td>
                                    </tr>
                                    <tr>
                                        <td>8.</td>
                                        <td>Contact Permission</td>
                                        <td>When you enable us access to your mobile device's address book, we can use the names and contact information to send invitations and assess your phone usage and habits. As part of the App journey, we access your phonebook contacts, including their contact names, phone numbers, account kinds, favourites (starred), and contact labels, to enrich your profile. We utilise this information to determine your social network based on your phonebook contacts, identify fraudulent contacts in your network, and conduct promotional and marketing activities. This helps us detect fraud and reduce risk.
                                        </td>
                                        <td>Enabling the App and its Services; Marketing and Outreach; Automated Decisions; and Legal Compliance and Requirements.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <p>Please note that a few data points collected here are mandatory for building a comprehensive credit risk assessment and credit profile and thus shall be securely shared with our registered service providers, affiliates, and partners. All the data collected with the access of above mentioned permissions are stored to Credfin servers and is used as a part of assessment.</p>

                        <h3>USE AND DISCLOSURE OF YOUR PERSONAL AND OTHER INFORMATION</h3>

                        <p>We use personal information to provide the services you request, to customize your user experience and to improve our services. To the extent we intent to use your personal information to market any product to you, we will provide you the ability to opt-out of such uses. We use your personal information to:</p>
                        <ol>
                            <li>troubleshoot problems;</li>
                            <li>help promote a safe service;</li>
                            <li>analytical analysis;</li>
                            <li>measure consumer interest and satisfaction in our products and services;</li>
                            <li>inform you about online and offline offers, products, services, and updates;</li>
                            <li>customize your experience;</li>
                            <li>detect and protect us against suspicious or illegal activity, fraud and other criminal activity</li>
                            <li>
                                enforce our terms and conditions;
                            </li>
                            <li>improvement of our services and as otherwise described to you at the time of collection</li>
                        </ol>

                        <p>In our efforts to continually improve our product and service offerings, we collect and analyze demographic and profile data about our users' activity on our Application.</p>

                        <h3>USE OF YOUR DEVICE INFORMATION</h3>
                        <p>We use the information provided by you in the following ways:</p>
                        <ol>
                            <li>to establish identity and verify the same;</li>
                            <li>monitor, improve and administer our Application/ Platform ;</li>
                            <li>provide our service i.e. perform credit profiling for the purpose of facilitating loans to You.</li>
                            <li>design and offer customized products and services;</li>
                            <li>analyze how the Application is used, diagnose service or technical problems and maintain security;</li>
                            <li>send communications notifications, information regarding the products or services;</li>
                            <li>manage our relationship with You and inform You about other services We think You might find of some use;</li>
                            <li>conduct data analysis in order to improve the Services / Products provided to the User;</li>
                            <li>use the User information in order to comply with country laws and regulations;</li>
                            <li>to conduct KYC based on the information shared by the User;</li>
                            <li>
                                use the User information in other ways permitted by law to enable You to take financial services from us.
                            </li>
                        </ol>
                        <p>We will use and retain Your information for such periods as necessary to provide You the Services on our Application, to comply with our legal obligations, to resolve disputes, and enforce our agreements.</p>

                        <h3>DISCLOSURE TO THIRD PARTIES</h3>
                        <p>We will share Your information with third parties including our regulated financial partners for provision of services on the Website and/or for facilitation of a loan/facility to a User. We will share Your information with third-parties only in such manner as described below:</p>
                        <ol>
                            <li>We may disclose Your information, without prior notice, if We are under a duty to do so in order to comply with any legal obligation or an order from the government and/or a statutory authority, or in order to enforce or apply Our terms of use or assign such information in the course of corporate divestitures, mergers, or to protect the rights, property, or safety of Us, Our users, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
                            <li>We will disclose the data/information provided by a User with other technology partners to track how the User interacts with Website on Our behalf.</li>
                            <li>
                                We and our affiliates may share Your information with another business entity should we (or our assets) merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business for continuity of business. Should such a transaction occur than any business entity (or the new combined entity) receiving any such information from Us shall be bound by this Policy with respect to your information.
                            </li>
                            <li>
                                We will disclose the information to our third-party technology and credit partners to perform credit checks and credit analysis like Credit Bureaus or third-party data source providers;
                            </li>

                            <li>
                                We will disclose Your information under a confidentiality agreement with the third parties and restrict the use of the said Information by third-parties only for the purposes of detailed hereinabove.
                            </li>
                        </ol>
                        <p>In case we use or disclose You information for any purpose not specified above, we will take your explicit consent.</p>
                        <h3>YOUR PRIVACY CONTROLS</h3>
                        <p>You have certain choices regarding the information we collect and how it is used:</p>
                        <ol>
                            <li>Device-level settings: Your device may have controls that determine what information we collect. For example, you can modify permissions on your Android device for access to Camera or other permissions.</li>
                            <li>You can also request to remove content from our servers based on applicable law or by writing to our Grievance Officer.</li>
                        </ol>
                        <h3>SECURITY PRECAUTIONS</h3>
                        <p>The Website/App is built with strict security features that continuously protect your information.</p>
                        <p>Our Platform has stringent security measures in place to protect the loss, misuse, and alteration of information under control. We endeavor to safeguard and ensure the security of the information provided by you. We use Secure Sockets Layers (SSL) based encryption, for the transmission of the information, which is currently the required level of encryption in India as per the law.</p>
                        <p>In addition, the Website and Application have been audited by a Cert-in auditor XIARCH SOLUTIONS PVT LTD and have been certified for:</p>
                        <ol>
                            <li>Encrypted communication.</li>
                            <li>Secured communication lines.</li>
                            <li>Information Security Policy in line with security standards such as ISO 27001.</li>
                            <li>Compliance review of controls.</li>
                            <li>Adherence to standards such as ISO 27001 to maintain Information Security.
                            </li>
                            <li>Compliance to prevailing laws such as IT Act 2000.
                            </li>
                            <li>Software to prevent malware/virus attacks</li>
                        </ol>

                        <p>We work hard to protect from unauthorized access, alteration, disclosure or destruction of information we hold, including:</p>

                        <ol>
                            <li>We use encryption to keep your data private while in transit;
                            </li>
                            <li>
                                We offer security feature like an OTP verification to help you protect your account;
                            </li>
                            <li>
                                We review our information collection, storage, and processing practices, including physical security measures, to prevent unauthorized access to our systems;
                            </li>
                            <li>
                                We restrict access to personal information to our employees, contractors, and agents who need that information in order to process it. Anyone with this access is subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.
                            </li>
                            <li>Compliance & Cooperation with Regulations and applicable laws;</li>
                            <li>
                                We regularly review this Privacy Policy and make sure that we process your information in ways that comply with it.
                            </li>
                            <li>
                                Data transfers
                            </li>
                            <li>
                                We maintain your information on servers located in India. Data protection laws vary among countries, with some providing more protection than others. We also comply with certain legal frameworks relating to the transfer of data as mentioned and required under the Information Technology Act, 2000.
                            </li>
                            <li>
                                When we receive formal written complaints, we respond by contacting the person who made the complaint. We work with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of your data that we cannot resolve with you directly.
                            </li>
                            <li>
                                Bureau Enquiry
                            </li>
                            <li>
                                We will enquire with one or more Credit Bureaus on one or more National Banking Financial Company's (NBFC) behalf to provide you with your loan amount.
                            </li>
                        </ol>

                        <h3>CHOICE/OPT-OUT</h3>
                        <p>We provide all users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications from from us in general, after setting up an account.</p>
                        <p>If you want to remove your contact information from all lists and newsletters, please contact us.</p>
                        <p>If you wish to remove your data, follow the steps below -</p>
                        <ol>
                            <li>Mail your request at finance@credfin.in</li>
                            <li>
                                The subject of the mail should be DELETE PROFILE INFORMATION
                            </li>
                            <li>
                                One of our team member will contact you for knowing the reason and taking final consent on-call.
                            </li>
                            <li>
                                After this, your request will be processed within 3-5 working days.
                            </li>
                            <li>
                                We will send you a confirmation mail for the same.
                            </li>
                        </ol>

                        <h3>
                            LINKS TO OTHER SITES
                        </h3>
                        <p>
                            Our Website links to other websites that may collect personally identifiable information about you. We are not responsible for the privacy practices or the content of those linked websites. With this Policy we're only addressing the disclosure and use of data collected by Us. If You visit any websites through the links on the Website, please ensure You go through the privacy policies of each of those websites. Their data collection practices and their policies might be different from this Policy and We do not have control over any of their policies neither do we have any liability in this regard.
                        </p>

                        <h3>ADVERTISEMENTS ON THE WEBSITE / APPLICATION</h3>
                        <p>
                            We do not advertise on our website / application
                        </p>
                        <h3>
                            YOUR CONSENT
                        </h3>
                        <p>
                            By using the Website/Application and/ or by providing your information, you consent to the collection and use of the information you disclose on the Website in accordance with this Privacy Policy, including but not limited to Your consent for collecting, using, sharing and disclosing your information as per this privacy policy.
                        </p>
                        <p>
                            If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.
                        </p>

                    </div>
                </div>
            </div>
            <Footer2 />
        </>
    )
}
