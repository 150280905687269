const routes = {
    Homepage: '/',
    FAQ: '/home/FAQ',
    PrivacyPolicy: '/home/PrivacyPolicy',
    TermsCondition: '/home/TermsCondition',
    GreivenceRedressal : '/home/GreivenceRedressal',
    Support : '/home/Support',
    CancellationPage : '/home/CancellationPolicy',
    Aboutus : '/home/Aboutus',
    Contactus : '/home/ContactUs',
    Message : '/home/message',
    OurPartner : '/home/ourPartner',
}
export default routes;